import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { Button, Switch, Box, Paper, Typography, FormControl, Modal, Select, MenuItem, FormHelperText, InputLabel, TextField, IconButton } from '@mui/material';
import { Formik } from "formik";
import { DataGrid } from '@mui/x-data-grid';
import * as yup from "yup";
import dayjs from 'dayjs';
import axios from 'axios';
import { useMycontext } from '../components/global/MyContext';
import { useSnackbar } from 'notistack';
import { Delete, Edit } from '@mui/icons-material';
import { setHiddenColumn } from '../store/globalSlice';
import SwipeableEdgeDrawer from '../components/bottomdrawer';
import { useDispatch, useSelector } from "react-redux";

const Video = () => {

    const columns = [
        {
            field: 'sno',
            headerName: 'S.No',
            type: "number",
            filterable: false,
            width: 50,
        },
        {
            field: 'eng_category',
            headerName: 'Category',
            minWidth: 70,
        },
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'link',
            headerName: 'Video Link',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'posted_by',
            headerName: 'Posted By',
            minWidth: 70,
        },
        {
            field: 'visiblity',
            headerName: 'Visiblity',
            width: 80,
            disableClickEventBubbling: true,

            renderCell: (params) => {
                const onClick = (event) => {
                    const currentRow = params.row;
                    axios.get(apiUrl + 'video/visiblity?id=' + currentRow.id)
                        .then(function (response) {
                            if (response.data.status) {
                                const updatedRows = rows.map(row => {
                                    if (row.id === currentRow.id) {
                                        return { ...row, visiblity: currentRow.visiblity == '1' ? '0' : '1' };
                                    }
                                    return row; // Otherwise, return the original row
                                });
                                setRows(updatedRows);
                            }
                            else {
                                setRows([]);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
                return (
                    <IconButton onClick={(event) => { event.stopPropagation(); }}>
                        <Switch
                            checked={params.row.visiblity == '1' ? true : false}
                            onChange={onClick}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /></IconButton>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            renderCell: (params) => {
                const onClick = (event) => {
                    const currentRow = params.row;
                    axios.get(apiUrl + 'video/status?id=' + currentRow.id)
                        .then(function (response) {
                            if (response.data.status) {
                                const updatedRows = rows.map(row => {
                                    if (row.id === currentRow.id) {
                                        return { ...row, status: currentRow.status == '1' ? '0' : '1' };
                                    }
                                    return row; // Otherwise, return the original row
                                });
                                setRows(updatedRows);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
                return (
                    <IconButton onClick={(event) => { event.stopPropagation(); }}>
                        <Switch
                            checked={params.row.status == '1' ? true : false}
                            onChange={onClick}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </IconButton>
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 160,
            valueGetter: (params) =>
                `${dayjs(params.row.created_at).format('DD-MMM-YYYY')}`,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 70,
            renderCell: (params) => {
                const onClick = (event) => {
                    event.stopPropagation();
                    handleDelOpen(params.row.id);
                };
                return (
                    <>
                        <IconButton onClick={onClick}><Delete color='error' /></IconButton>
                    </>

                );
            },
        },
    ];

    const { apiUrl } = useMycontext();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [video, setVideo] = useState(null);
    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const hiddenColumn = useSelector((state) => state.global.hiddenColumn);
    const [selVideo, setSelVideo] = useState(null);

    const [open, setOpen] = useState(false);
    const handleOpen = (url) => { setOpen(true); setVideo(url) };
    const handleClose = () => { setOpen(false); setVideo(null) };

    const [dopen, setDOpen] = useState(false);
    const handleDOpen = () => setDOpen(true);
    const handledClose = () => setDOpen(false);

    const [delOpen, setDelOpen] = useState(false);
    const handleDelOpen = (id) => { setDelOpen(true); setSelVideo(id) };
    const handleDelClose = () => { setDelOpen(false); setSelVideo(null) };


    const handleFormSubmit = (values, onSubmitProps) => {
        const parts = values.link.split('/');
        const formData = new FormData();
        formData.append('catid', values.category);
        formData.append('title', values.title);
        formData.append('link', values.link);
        formData.append('video_id', parts[parts.length - 1]);
        formData.append('visiblity', values.visiblity);
        formData.append('status', values.status);
        setLoading(true);
        axios.post(apiUrl + 'video/add', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    onSubmitProps.resetForm();
                    setLoading(false);
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const getCategory = () => {
        axios.get(apiUrl + 'category/get')
            .then(function (response) {
                if (response.data.status) {
                    setCategory(response.data.data);
                }
                else {
                    setCategory([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getVideos = () => {
        axios.get(apiUrl + 'video/getall?offset=0')
            .then(function (response) {
                if (response.data.status) {
                    setRows(response.data.data.map((row, index) => ({ ...row, sno: index + 1 })));
                }
                else {
                    setRows([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const deleteVideo = (id) => {
        setLoading(true);
        axios.delete(apiUrl + 'video/delete?id=' + id)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    getVideos();
                    handleDelClose();
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        getCategory();
        getVideos();
    }, []);
    return (
        <>
            <Box>
                <Paper sx={{ borderRadius: "15px", padding: { xs: "5px", md: "10px 5px 0px 10px" }, flex: 1, display: { xs: "block", md: "none" } }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                        <Typography variant='h3' sx={{ padding: "0px 5px" }}>वीडियो विवरण</Typography>
                        <Button
                            onClick={handleDOpen}
                            type='submit'
                            sx={{ fontSize: "15px", borderRadius: "10px" }}
                            variant="contained"
                        >Add Video</Button>
                    </Box>
                </Paper>
                <Paper sx={{ padding: "10px", display: { xs: "none", md: "block" } }}>
                    <Formik
                        enableReinitialize={true}
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography variant='h3' sx={{ padding: "0px 5px" }}>वीडियो विवरण</Typography>
                                    <Box sx={{ display: "flex", gap: "10px" }}>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="demo-select-Category">समूह</InputLabel>
                                            <FormControl variant='outlined' fullWidth size='small'>
                                                <Select
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.category}
                                                    name="category"
                                                    error={!!touched.category && !!errors.category}
                                                >
                                                    {category.map((item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={item.id}>{item.sub_category}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                                <FormHelperText error={!!touched.category && !!errors.category}>{touched.category && errors.category}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '10px', flex: 1 }}>
                                            <Box sx={{ flex: 1 }}>
                                                <InputLabel id="demo-select-Category">दृश्यता</InputLabel>
                                                <FormControl variant='outlined' fullWidth size='small'>
                                                    <Select
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.visiblity}
                                                        name="visiblity"
                                                        error={!!touched.visiblity && !!errors.visiblity}
                                                    >
                                                        <MenuItem value="1">Show</MenuItem>
                                                        <MenuItem value="0">Hidden</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={!!touched.visiblity && !!errors.visiblity}>{touched.visiblity && errors.visiblity}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <InputLabel id="demo-select-Category">स्थिति</InputLabel>
                                                <FormControl variant='outlined' fullWidth size='small'>
                                                    <Select
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.status}
                                                        name="status"
                                                        error={!!touched.status && !!errors.status}
                                                    >
                                                        <MenuItem value="1">Show</MenuItem>
                                                        <MenuItem value="0">Hidden</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={!!touched.status && !!errors.status}>{touched.status && errors.status}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px" }}>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="title">वीडियो शीर्षक</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Video Title"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.title}
                                                name="title"
                                                error={!!touched.title && !!errors.title}
                                                helperText={touched.title && errors.title}
                                            />
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="demo-select-Category">वीडियो लिंक</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                variant="outlined"
                                                type="text"
                                                placeholder="Vidoe Link"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.link}
                                                name="link"
                                                error={!!touched.link && !!errors.link}
                                                helperText={touched.link && errors.link}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", gap: "80px" }}>
                                        <Button
                                            type='submit'
                                            sx={{ fontSize: "15px", margin: "10px 0px", borderRadius: "10px" }}
                                            variant="contained"
                                        >Upload Video</Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </Box>

            <Paper sx={{ maxWidth: { xs: "calc(100vw - 15px)", md: "calc(100vw - 230px)" }, height: { xs: "calc(100vh - 200px)", md: "calc(100vh - 368px)" }, marginTop: "10px", borderRadius: "15px" }}>
                <DataGrid
                    sx={{ borderRadius: "15px" }}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    onPaginationModelChange={(params) => setPage(params.page)}
                    hideFooterSelectedRowCount={true}
                    pageSizeOptions={[100]}
                    initialState={{
                        columns: {
                            columnVisibilityModel: hiddenColumn.payload
                        },
                    }}
                    onRowClick={(e) => handleOpen(e.row.link)}
                    onColumnVisibilityModelChange={(params) => { dispatch(setHiddenColumn(params)) }}
                    rowCount={rowCount}
                    paginationMode="server"
                />
            </Paper>

            <SwipeableEdgeDrawer open={dopen} setOpen={setDOpen}>
                <Box sx={{ padding: "10px" }}>
                    <Formik
                        enableReinitialize={true}
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography variant='h3' sx={{ padding: "0px 5px" }}>वीडियो विवरण</Typography>
                                    <Box sx={{ display: "flex", gap: "10px" }}>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="demo-select-Category">समूह</InputLabel>
                                            <FormControl variant='outlined' fullWidth size='small'>
                                                <Select
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.category}
                                                    name="category"
                                                    error={!!touched.category && !!errors.category}
                                                >
                                                    {category.map((item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={item.id}>{item.sub_category}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                                <FormHelperText error={!!touched.category && !!errors.category}>{touched.category && errors.category}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '10px', flex: 1 }}>
                                            <Box sx={{ flex: 1 }}>
                                                <InputLabel id="demo-select-Category">दृश्यता</InputLabel>
                                                <FormControl variant='outlined' fullWidth size='small'>
                                                    <Select
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.visiblity}
                                                        name="visiblity"
                                                        error={!!touched.visiblity && !!errors.visiblity}
                                                    >
                                                        <MenuItem value="1">Show</MenuItem>
                                                        <MenuItem value="0">Hidden</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={!!touched.visiblity && !!errors.visiblity}>{touched.visiblity && errors.visiblity}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <InputLabel id="demo-select-Category">स्थिति</InputLabel>
                                                <FormControl variant='outlined' fullWidth size='small'>
                                                    <Select
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.status}
                                                        name="status"
                                                        error={!!touched.status && !!errors.status}
                                                    >
                                                        <MenuItem value="1">Show</MenuItem>
                                                        <MenuItem value="0">Hidden</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={!!touched.status && !!errors.status}>{touched.status && errors.status}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px" }}>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="title">वीडियो शीर्षक</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Video Title"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.title}
                                                name="title"
                                                error={!!touched.title && !!errors.title}
                                                helperText={touched.title && errors.title}
                                            />
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="demo-select-Category">वीडियो लिंक</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                variant="outlined"
                                                type="text"
                                                placeholder="Vidoe Link"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.link}
                                                name="link"
                                                error={!!touched.link && !!errors.link}
                                                helperText={touched.link && errors.link}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", gap: "80px" }}>
                                        <Button
                                            type='submit'
                                            sx={{ fontSize: "15px", margin: "20px 0px", borderRadius: "10px" }}
                                            variant="contained"
                                        >Upload Video</Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </SwipeableEdgeDrawer>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 500,
                    minWidth: 380,
                    padding: "10px",
                    height: "80vh",
                    overflowX: "hidden"
                }}>
                    <ReactPlayer
                        url={video}
                        width="100%"
                        height="100%"
                        playing={true}
                        loop={true}
                        config={{
                            youtube: {
                                playerVars: { modestbranding: 1 }
                            }
                        }}
                    />
                </Paper>
            </Modal>

            <Modal
                open={delOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 600,
                    minWidth: 350,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Do you want to Delete Plot?
                    </Typography>
                    <Box sx={{ display: "flex", gap: '10px', marginTop: "30px" }}>
                        <Button
                            fullWidth size="large"
                            sx={{ borderRadius: "5px", margin: "0px", height: "40px" }}
                            variant="contained"
                            color="primary"
                            onClick={handleDelClose}
                        >No</Button>
                        <Button
                            fullWidth size="large"
                            sx={{ borderRadius: "5px", margin: "0px", height: "40px" }}
                            variant="contained"
                            color="error"
                            onClick={() => { deleteVideo(selVideo) }}
                        >Yes</Button>
                    </Box>
                </Paper>
            </Modal>


        </>
    )
}

const initialValues = {
    category: "",
    title: "",
    link: "",
    visiblity: "1",
    status: "1",
}

const checkoutSchema = yup.object().shape({
    category: yup.string().required("आवश्यक"),
    visiblity: yup.string().required("आवश्यक"),
    status: yup.string().required("आवश्यक"),
    title: yup.string().required("आवश्यक"),
    link: yup.string().required("आवश्यक"),
})

export default Video