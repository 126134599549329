import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userId: { type: "shabdshodh/setUserId", payload: null },
    name: { type: "shabdshodh/setName", payload: null },
    hiddenColumn: { type: "shabdshodh/setHiddenColumn", payload: {} }
};

export const globalSlice = createSlice({
    name: "shabdshodh",
    initialState,
    reducers: {
        setUserId: (state, userId) => {
            state.userId = userId;
        },
        setName: (state, name) => {
            state.name = name;
        },
        setHiddenColumn: (state, column) => {
            state.hiddenColumn = column;
        }
    },
});

export const { setLogin } = globalSlice.actions;
export const { setUserId } = globalSlice.actions;
export const { setName } = globalSlice.actions;
export const { setRole } = globalSlice.actions;
export const { setHiddenColumn } = globalSlice.actions;

export default globalSlice.reducer;