import React, { useEffect, useState } from 'react'
import { Box, InputLabel, FormControl, InputAdornment, Modal, FormHelperText, Paper, Button, styled, IconButton, Typography } from '@mui/material';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Event, CloudUpload, ArrowBack, ArrowForward, Delete } from '@mui/icons-material';
import dayjs from 'dayjs';
import axios from 'axios';
import Pdf from '../components/pdf';
import { useTheme } from '@emotion/react';
import { useMycontext } from '../components/global/MyContext';
import { useSnackbar } from 'notistack';
import CircleLoading from '../components/global/circleloading';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Epaper = () => {

    const theme = useTheme();
    const { apiUrl } = useMycontext();
    const { enqueueSnackbar } = useSnackbar();
    const [openFile, setOpenFile] = useState("");
    const [paperDate, setPaperDate] = useState(dayjs());
    const [paper, setPaper] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = (file) => { setOpen(true); setOpenFile(file); };
    const handleClose = () => { setOpen(false); setOpenFile(""); };

    const prevPage = () => { setPage(page - 1 <= 1 ? 1 : page - 1) }
    const nextPage = () => { setPage(page + 1 >= totalPage ? totalPage : page + 1) }

    const uploadPdf = (file) => {
        const formData = new FormData();
        if (file.file !== null) {
            formData.append('pdf', file, file.name);
            formData.append('date', dayjs(paperDate).format('DD-MMMM-YYYY'));
        }
        else {
            enqueueSnackbar("Please Select a Valid file.", { variant: "error" });
        }
        setLoading(true);
        axios.post(apiUrl + 'epaper/add', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    setLoading(false);
                    getPdfFiles();
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const getPdfFiles = () => {
        axios.get(apiUrl + 'epaper/getall')
            .then(function (response) {
                if (response.data.status) {
                    setPaper(response.data.data);
                }
                else {
                    setPaper([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const deletePaper = (file_name) => {
        axios.delete(apiUrl + 'epaper/delete?filename=' + file_name)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    getPdfFiles();
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getPdfFiles();
    }, []);

    return (
        <>
            {loading && <CircleLoading />}
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                <Paper sx={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: '10px', borderRadius: "10px" }}>
                    <Button size='' component="label" variant="contained" startIcon={<CloudUpload />} sx={{ borderRadius: "10px", fontSize: "16px" }}>
                        Upload file
                        <VisuallyHiddenInput type="file" onChange={(e) => { uploadPdf(e.target.files[0]) }} />
                    </Button>
                    <Box>
                        <FormControl variant='outlined' sx={{ width: "20ch" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    format="DD-MMM-YYYY"
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            InputProps: {
                                                endAdornment: (
                                                    <InputAdornment position="end"><Event /></InputAdornment>
                                                )
                                            },
                                        }
                                    }}
                                    value={paperDate}
                                    onChange={(e) => { setPaperDate(e) }}
                                    name="news_date"
                                />
                            </LocalizationProvider>
                            <FormHelperText></FormHelperText>
                        </FormControl>
                    </Box>
                </Paper>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: '10px', padding: { xs: "7px 5px 70px 5px", md: "10px 5px" }, width: "100%", overflowX: "hidden" }}>
                {paper.map((item, index) => {
                    return (
                        <Paper key={index} sx={{ flexGrow: 1, maxWidth: "300px", textAlign: 'center', padding: "2px" }} onClick={() => { handleOpen(item.file_name) }}>
                            <Pdf url={apiUrl + "assets/e-paper/" + item.file_name} ratio={1} />
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 10px" }}>
                                <Typography variant='h5'>{item.date}</Typography>
                                <IconButton onClick={(event) => { deletePaper(item.file_name); event.stopPropagation(); }}><Delete fontSize='small' color='error' /></IconButton>
                            </Box>
                        </Paper>
                    )
                })}
            </Box >

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <IconButton onClick={prevPage} color='primary' disabled={page == 1 ? true : false} sx={{ border: "2px solid " + theme.palette.primary.main, padding: "5px", position: "fixed", top: "50%", left: "6%", zIndex: "999" }}><ArrowBack /></IconButton>
                    <IconButton onClick={nextPage} color='primary' disabled={page == totalPage ? true : false} sx={{ border: "2px solid " + theme.palette.primary.main, padding: "5px", position: "fixed", top: "50%", right: "6%", zIndex: "999" }}><ArrowForward /></IconButton>

                    <Paper sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "90vw",
                        minWidth: 380,
                        padding: "0px",
                        height: { xs: "auto", sm: "80vh", md: "90vh" },
                        overflowX: "hidden"
                    }}>
                        <Pdf url={apiUrl + "assets/e-paper/" + openFile} ratio={3} page={page} setTotalPage={setTotalPage} />
                        <Box sx={{ marginTop: "-50px", marginBottom: "20px", textAlign: "center" }}>
                            <Button variant='outlined' color='error' onClick={handleClose}>Close</Button>
                        </Box>
                    </Paper>
                </Box>
            </Modal>
        </>
    )
}

export default Epaper