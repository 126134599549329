import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../components/global/sidebar'
import Navbar from '../components/global/navbar'
import Navdown from '../components/global/navdown'

const Main = (props) => {
    return (
        <>
            <Box sx={{ display: "flex", width: "100%", height: "100#" }}>
                <Sidebar setRole={props.setRole} role={props.role} />
                <Box sx={{ flex: 1, padding: { xs: "5px 5px 0px 5px", md: "10px 10px 0px 10px" } }}>
                    <Navbar />
                    <Navdown />
                    <Box sx={{ padding: { xs: "65px 0px 70px 0px", md: "10px 0px" } }}>
                        <Outlet />
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default Main