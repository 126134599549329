import { Button, Paper, Typography, Box, TextField, useTheme } from '@mui/material'
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setName, setUserId } from '../../../store/globalSlice';
import loginVector from '../../../assets/images/login.png';
import sslogo from '../../../assets/images/sslogo.png';
import { useMycontext } from '../../../components/global/MyContext';

const Reset = (props) => {

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const theme = useTheme();

    const { apiUrl, user } = useMycontext();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleFormSubmit = (values) => {
        callReset(values);
    }

    const callReset = (values) => {
        const formData = new FormData();
        formData.append('mobile_no', user.mobile);
        formData.append('password', values.password);
        formData.append('otp', user.otp);
        axios.post(apiUrl + 'admin/register/reset', formData)
            .then(function (response) {
                if (response.data.status) {
                    dispatch(setUserId(response.data.data.id));
                    dispatch(setName(response.data.data.name));
                    props.setRole(response.data.data.role);
                    navigate('/');
                }
                else {
                    setError(true);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <Box sx={{
                height: "100vh",
                backgroundImage: "url(./image/background.webp)",
                backgroundPosition: "center",
                backgroundSize: "100% auto"
            }}>
                <Paper sx={{
                    display: "flex",
                    width: "90%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "20px"
                }}>
                    <Box sx={{
                        backgroundColor: "#ddffff",
                        flex: 2,
                        textAlign: "center",
                        display: { xs: "none", md: "flex" },
                        justifyContent: "center",
                        alignItems: "center",
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        height: { xs: "auto", md: "calc(100vh - 100px)" }
                    }}>
                        <Box>
                            <img src={loginVector} alt="login-img" width="100%" />
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: { xs: "auto", md: "calc(100vh - 100px)" }
                        }}>
                            <Box sx={{
                                width: "100%",
                                padding: "50px",
                            }}>
                                <Box sx={{ textAlign: "center" }}>
                                    <img src={sslogo} alt="" height="200px" />
                                </Box>
                                <Box sx={{ textAlign: "center", margin: "10px" }}>
                                    <Typography variant='h1'>स्वागतं रिपोर्टर</Typography>
                                </Box>
                                <Formik
                                    onSubmit={handleFormSubmit}
                                    initialValues={initialValues}
                                    validationSchema={checkoutSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="password"
                                                    label="Password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    name="password"
                                                    error={!!touched.password && !!errors.password}
                                                    helperText={touched.password && errors.password}
                                                />
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="password"
                                                    label="Confirm Password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.cpassword}
                                                    name="cpassword"
                                                    error={!!touched.cpassword && !!errors.cpassword}
                                                    helperText={touched.cpassword && errors.cpassword}
                                                />
                                                <Box sx={{ textAlign: "center" }}>Already have account? <Link to='/login'><Typography variant="span" color={theme.palette.primary.main}>Login</Typography></Link></Box>
                                                <Button
                                                    fullWidth
                                                    sx={{ borderRadius: "40px", marginTop: "20px" }}
                                                    variant="contained"
                                                    type='submit'
                                                >Reset</Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </>


    )
}

const passExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

const checkoutSchema = yup.object().shape({
    password: yup.string().matches(passExp, "Password must contain minimum eight characters, at least one letter and one number.").required("Required"),
    cpassword: yup.string().matches(passExp, "Password must contain minimum eight characters, at least one letter and one number.").oneOf([yup.ref('password'), null], 'Passwords must match').required("Required"),
});

const initialValues = {
    password: "",
    cpassword: "",
};

export default Reset