import { Button, Paper, Typography, Box, TextField } from '@mui/material'
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import * as yup from "yup";
import axios from 'axios';
import loginVector from '../../../assets/images/login.png';
import sslogo from '../../../assets/images/sslogo.png';
import { useMycontext } from '../../../components/global/MyContext';


const Loginotp = (props) => {

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { apiUrl, setUser } = useMycontext();

    const handleFormSubmit = (values) => {
        sendOTP(values);
    }

    const sendOTP = (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('mobile_no', values.mobile);
        axios.post(apiUrl + 'admin/register/sendotp', formData)
            .then(function (response) {
                if (response.data.status) {
                    setUser({ 'mobile': values.mobile });
                    navigate('/pass-verify');
                    setLoading(false);
                }
                else {
                    setError(true);
                    setErrorMsg(response.data.message);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    return (
        <>
            <Box sx={{
                height: "100vh",
                backgroundImage: "url(/image/background.webp)",
                backgroundPosition: "center",
                backgroundSize: "100% auto"
            }}>
                <Paper sx={{
                    display: "flex",
                    width: "90%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "20px"
                }}>
                    <Box sx={{
                        backgroundColor: "#ddffff",
                        flex: 2,
                        textAlign: "center",
                        display: { xs: "none", md: "flex" },
                        justifyContent: "center",
                        alignItems: "center",
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        height: { xs: "auto", md: "calc(100vh - 100px)" }
                    }}>
                        <Box>
                            <img src={loginVector} alt="login-img" width="100%" />
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: { xs: "auto", md: "calc(100vh - 100px)" }
                        }}>
                            <Box sx={{
                                width: "100%",
                                padding: "50px",
                            }}>
                                <Box sx={{ textAlign: "center" }}>
                                    <img src={sslogo} alt="" height="200px" />
                                </Box>
                                <Box sx={{ textAlign: "center", margin: "10px" }}>
                                    <Typography variant='h1'>स्वागतं रिपोर्टर</Typography>
                                </Box>
                                <Formik
                                    onSubmit={handleFormSubmit}
                                    initialValues={initialValues}
                                    validationSchema={checkoutSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="number"
                                                    label="Mobile"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.mobile}
                                                    name="mobile"
                                                    error={!!touched.mobile && !!errors.mobile}
                                                    helperText={touched.mobile && errors.mobile}
                                                />
                                                <Button
                                                    fullWidth
                                                    sx={{ borderRadius: "40px", marginTop: "20px" }}
                                                    variant="contained"
                                                    type='submit'
                                                >Send OTP</Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </>


    )
}

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    mobile: yup.string().min(10).max(10).matches(phoneRegExp, "Phone number is not valid.").required("Required"),
});

const initialValues = {
    mobile: "",
};

export default Loginotp