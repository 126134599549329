import React, { useState, useEffect } from 'react'
import { Button, Avatar, Box, Paper, Typography, FormControl,Select, MenuItem, FormHelperText, InputLabel, TextField, IconButton, Modal } from '@mui/material';
import { Formik } from "formik";
import { Delete, Edit } from '@mui/icons-material';
import Compressor from 'compressorjs';
import * as yup from "yup";
import dayjs from 'dayjs';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useMycontext } from '../components/global/MyContext';
import SwipeableEdgeDrawer from '../components/bottomdrawer';
import { Share} from '@mui/icons-material';

const NewPost = () => {

    const { enqueueSnackbar } = useSnackbar();
    const { apiUrl } = useMycontext();
    const [file, setFile] = useState({ file: null });
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [post, setPost] = useState([]);
    const [onePost, setOnePost] = useState({});
    const [category, setCategory] = useState([]);
    const [edit, setEdit] = useState(null);
    const [initialValues, setInitialValues] = useState({
        cat_id: "",
        color_title: "",
        color: "#ff6600",
        title: "",
        description: "",
        size: "small",
    });

    const [open, setOpen] = useState(false);
    const handleOpen = (id, url) => { setOpen(true); getOnePost(id, url, false) };
    const handleClose = () => setOpen(false);

    const [dopen, setDOpen] = useState(false);
    const handleDOpen = (id) => setDOpen(true);
    const handleDClose = () => setDOpen(false);

    const [delOpen, setDelOpen] = useState(false);
    const handleDelOpen = (id) => { setDelOpen(true); setOnePost({ id: id }); };
    const handleDel2Open = () => { setDelOpen(true); };
    const handleDelClose = () => { setDelOpen(false); };

    const handlePChange = (event) => {
        if (event.target.files.length !== 1) {
            return;
        }
        const image = event.target.files[0];
        if ((image?.size) > 300 * 1024) {
            new Compressor(image, {
                quality: 0.6,
                maxWidth: 800,
                maxHeight: 800,
                maxSize: 300 * 1024, // maximum size in bytes
                success(result) {
                    setFile({ file: result, image: URL.createObjectURL(result) });
                },
                error(err) {
                    console.log(err.message);
                },
            });
        } else {
            setFile({ file: event.target.files[0], image: URL.createObjectURL(event.target.files[0]) });
        }

    }

    const handleFormSubmit = (values, onSubmitProps) => {
        const formData = new FormData();
        if (edit !== null) {
            formData.append('id', edit);
        }
        formData.append('catid', values.cat_id);
        formData.append('title', values.title);
        formData.append('colortitle', values.color_title);
        formData.append('color', values.color);
        formData.append('description', values.description);
        formData.append('size', values.size);
        if (file.file !== null) {
            formData.append('photo', file.file, file.file.name);
        }
        setLoading(true);
        axios.post(edit !== null ? apiUrl + 'post/update' : apiUrl + 'post/add', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    getRecent(false);
                    setFile({ file: null });
                    onSubmitProps.resetForm();
                    setLoading(false);
                    setEdit(null);
                    handleDClose();
                    setInitialValues({
                        cat_id: "",
                        color_title: "",
                        color: "#ff6600",
                        title: "",
                        description: "",
                        size: "small",
                    })
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const getCategory = () => {
        axios.get(apiUrl + 'category/get')
            .then(function (response) {
                if (response.data.status) {
                    setCategory(response.data.data);
                }
                else {
                    setCategory([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getRecent = (flag) => {
        axios.get(apiUrl + 'post/getallpost?offset=' + page)
            .then(function (response) {
                if (response.data.status) {
                    if (flag) {
                        setPost(prev => [...prev, ...response.data.data]);
                    }
                    else {
                        setPost(response.data.data);
                    }
                }
                else {

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getOnePost = (id, url, flag) => {
        axios.get(apiUrl + 'post/getone?url=' + url + '&id=' + id)
            .then(function (response) {
                if (response.data.status) {
                    if (flag) {
                        handleDOpen();
                        setInitialValues(response.data.data);
                        setFile({ file: null, image: apiUrl + "assets/post-img/" + response.data.data.id + ".png" })
                        setEdit(response.data.data.id);
                    }
                    else {
                        setOnePost(response.data.data);
                    }

                }
                else {
                    setOnePost({});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const deletePost = (id) => {
        axios.delete(apiUrl + 'post/delete?id=' + id)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    getRecent(false);
                    handleDelClose();
                    handleDClose();
                    handleClose();
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getRecent(true);
    }, [page]);

    useEffect(() => {
        getCategory();
    }, []);

    return (
        <>
            <Box sx={{ display: "flex", gap: "10px", flexDirection: { xs: "column", md: "row" } }}>
                <Paper sx={{ borderRadius: "15px", padding: "10px 5px 10px 10px", flex: 1.2, display: { xs: "none", md: "block" } }}>
                    <Typography variant='h4' sx={{ padding: "0px 5px" }}>पोस्ट विवरण</Typography>
                    <Box sx={{ marginTop: "5px", height: { xs: "auto", md: "calc(100vh - 133px)" }, overflowX: "hidden", paddingRight: "5px" }}>
                        <Formik
                            enableReinitialize={true}
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                resetForm
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "0px" }}>
                                        <Box sx={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                                            <Box sx={{ flex: 3, textAlign: "center" }}>
                                                <IconButton sx={{ padding: "0px", borderRadius: "10px" }} aria-label="upload picture" component="label" variant='outlined'>
                                                    <Avatar src={file.image} sx={{ width: "100%", height: "100%", aspectRatio: 3 / 2, borderRadius: "10px" }}></Avatar>
                                                    <input hidden accept="image/*" type="file" onChange={handlePChange} />
                                                </IconButton>
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <Box>
                                                    <InputLabel id="demo-select-Category">आकार</InputLabel>
                                                    <FormControl variant='outlined' fullWidth size='small'>
                                                        <Select
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.size}
                                                            name="size"
                                                            error={!!touched.size && !!errors.size}
                                                        >
                                                            <MenuItem value="big">Big</MenuItem>
                                                            <MenuItem value="small">Small</MenuItem>
                                                        </Select>
                                                        <FormHelperText error={!!touched.size && !!errors.size}>{touched.size && errors.size}</FormHelperText>
                                                    </FormControl>
                                                </Box>
                                                <Box>
                                                    <InputLabel id="demo-select-Category">समूह</InputLabel>
                                                    <FormControl variant='outlined' fullWidth size='small'>
                                                        <Select
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.cat_id}
                                                            name="cat_id"
                                                            error={!!touched.cat_id && !!errors.cat_id}
                                                        >
                                                            {category.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.id}>{item.sub_category}</MenuItem>
                                                                )
                                                            })}

                                                        </Select>
                                                        <FormHelperText error={!!touched.cat_id && !!errors.cat_id}>{touched.cat_id && errors.cat_id}</FormHelperText>
                                                    </FormControl>
                                                </Box>
                                                <Box>
                                                    <InputLabel id="title">रंग</InputLabel>
                                                    <FormControl variant='outlined' fullWidth size='small'>
                                                        <Select
                                                            sx={{ padding: "0px" }}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.color}
                                                            name="color"
                                                            error={!!touched.color && !!errors.color}
                                                        >
                                                            <MenuItem value="#ff0000" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#ff0000", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Red</Box></MenuItem>
                                                            <MenuItem value="#ff6600" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#ff6600", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Orange</Box></MenuItem>
                                                            <MenuItem value="#ff0088" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#ff0088", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Pink</Box></MenuItem>
                                                            <MenuItem value="#0062ff" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#0062ff", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Blue</Box></MenuItem>
                                                            <MenuItem value="#5d00ff" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#5d00ff", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Purple</Box></MenuItem>
                                                            <MenuItem value="#0f9100" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#0f9100", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Green</Box></MenuItem>

                                                        </Select>
                                                        <FormHelperText error={!!touched.color && !!errors.color}>{touched.color && errors.color}</FormHelperText>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <InputLabel id="title">रंगीन शीर्षक</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Colored Title"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.color_title}
                                                name="color_title"
                                                error={!!touched.color_title && !!errors.color_title}
                                                helperText={touched.color_title && errors.color_title}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel id="title">समाचार शीर्षक</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="News Title"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.title}
                                                name="title"
                                                error={!!touched.title && !!errors.title}
                                                helperText={touched.title && errors.title}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel id="demo-select-Category">समाचार आलेख</InputLabel>
                                            <TextField
                                                fullWidth
                                                multiline
                                                minRows={4}
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize", resize: "both" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="News Article"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                                name="description"
                                                error={!!touched.description && !!errors.description}
                                                helperText={touched.description && errors.description}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", gap: "80px" }}>
                                        <Button
                                            type='submit'
                                            sx={{ margin: "20px 0px", fontSize: "15px", borderRadius: "10px" }}
                                            variant="contained"
                                        >{edit !== null ? "Update Post" : "Add Post"}</Button>
                                        {edit !== null && <Button
                                            sx={{ margin: "20px 0px", fontSize: "15px", borderRadius: "10px" }}
                                            variant="outlined"
                                            color='error'
                                            onClick={() => {
                                                setEdit(null);
                                                setFile({ file: null });
                                                setInitialValues({
                                                    cat_id: "",
                                                    color_title: "",
                                                    color: "#ff6600",
                                                    title: "",
                                                    description: "",
                                                    size: "small",
                                                })
                                            }}
                                        >Reset</Button>}
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Paper >
                <Paper sx={{ borderRadius: "15px", padding: { xs: "5px", md: "10px 5px 0px 10px" }, flex: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                        <Typography variant='h4'>हाल की पोस्ट</Typography>
                        <Button
                            onClick={handleDOpen}
                            type='submit'
                            sx={{ marginBottom: "10px", fontSize: "15px", borderRadius: "10px", display: { xs: "block", md: "none" } }}
                            variant="contained"
                        >New Post</Button>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingRight: { xs: "0px", md: "10px" }, height: { xs: "calc(100vh - 150px)", md: "calc(100vh - 120px)" }, overflowX: "hidden" }}>
                        {post.map((item, index) => {
                            return (
                                <>
                                    <Box key={index} sx={{ display: "flex", gap: "8px", borderRadius: "15px", padding: "10px 0px" }} onClick={() => { handleOpen(item.id, item.eng_sub_category) }}>
                                        <Box sx={{ flex: { xs: 1.6, sm: 2, md: 2.2 } }}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Typography variant="h6" sx={{ backgroundColor: item.color !== null ? item.color : "#ff6600", color: "#ffffff", padding: "0px 4px", borderRadius: "4px", textTransform: "capitalize" }}>{item.eng_category}</Typography>
                                                <Typography variant='span' sx={{ fontSize: "14px" }}>
                                                    {dayjs().diff(item.created_at, 'minutes') < 60
                                                        ? dayjs().diff(item.created_at, 'minutes') + " मिनट पहले"
                                                        : dayjs().diff(item.created_at, 'hour') < 24
                                                            ? dayjs().diff(item.created_at, 'hour') + " घंटे पहले"
                                                            : dayjs().diff(item.created_at, 'day') < 6
                                                                ? dayjs().diff(item.created_at, 'day') + " दिन पहले"
                                                                : dayjs(item.created_at).format('DD-MMM-YYYY')}
                                                </Typography>
                                                <Box>
                                                    <IconButton color='primary' sx={{ padding: "0px 10px" }} onClick={(e) => { e.stopPropagation(); getOnePost(item.id, item.eng_sub_category, true) }}><Edit /></IconButton>
                                                    <IconButton color='error' sx={{ padding: "0px 10px" }} onClick={(e) => { e.stopPropagation(); handleDelOpen(item.id) }}><Delete /></IconButton>
                                                </Box>
                                            </Box>
                                            <Box sx={{ marginTop: "8px" }}>
                                                <Typography variant='p' sx={{ fontFamily: "'Hind', sans- serif", fontSize: { xs: "16px", lg: "18px" }, fontWeight: "600", textAlign: "justify", textJustify: "inter-word", color: item.color }}>{item.color_title !== null ? item.color_title + " : " : ""}</Typography>
                                                <Typography variant='p' sx={{ fontFamily: "'Hind', sans- serif", fontSize: { xs: "16px", lg: "18px" }, fontWeight: "600", textAlign: "justify", textJustify: "inter-word" }}>{item.title}</Typography>
                                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: "5px", paddingTop: "3px" }}>
                                                    <Box sx={{ flex: { xs: 1.6, sm: 2.5, md: 2.8 }, display: 'flex', alignItems: "center", gap: "5px" }}>
                                                        <Avatar sx={{ height: "20px", maxWidth: "20px" }}></Avatar>
                                                        <Typography color='secondary' variant='h6' sx={{ marginTop: "2px" }}> {item.posted_by}</Typography>
                                                    </Box>
                                                    <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", height: "20px" }}>
                                                        {item.status == 'pending' &&
                                                            <Button size='small' variant='outlined' color='warning' onClick={(e) => { e.stopPropagation() }}>Pending</Button>
                                                        }
                                                        {item.status == 'approved' &&
                                                            <Button size='small' variant='outlined' color='success' onClick={(e) => { e.stopPropagation() }}>Approved</Button>
                                                        }
                                                        {item.status == 'rejected' &&
                                                            <Button size='small' variant='outlined' color='error' onClick={(e) => { e.stopPropagation() }}>Rejected</Button>
                                                        }
                                                        <IconButton sx={{ padding: "5px", display: { xs: "none", sm: "block" } }} color='primary'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                navigator.clipboard.writeText("TEXT_TO_BE_COPIED")
                                                                    .then(() => alert("Link Copied"))
                                                            }}><Share />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ flex: 1, minHeight: "120px", maxHeight: { xs: "150px", md: "200px" }, cursor: "pointer" }}>
                                            <Avatar src={apiUrl + "assets/post-img/" + item.id + ".png"} sx={{ borderRadius: "10px", width: "100%", height: "100%" }} />
                                        </Box>
                                    </Box >
                                    <hr></hr>
                                </>
                            )
                        })}

                        <Button sx={{ margin: "10px", width: '200px' }} size='small' variant='outlined' onClick={() => setPage(prev => prev + 1)}>Load More</Button>
                    </Box>
                </Paper >
            </Box >

            <SwipeableEdgeDrawer open={dopen} setOpen={setDOpen}>
                <Box sx={{ borderRadius: "15px", padding: "10px 5px 10px 10px", flex: 1 }}>
                    <Box>
                        <Typography variant='h4' sx={{ padding: "0px 5px" }}>पोस्ट विवरण</Typography>
                    </Box>

                    <Box sx={{ marginTop: "15px", height: { xs: "auto", md: "calc(100vh - 153px)" }, overflowX: "hidden", paddingRight: "5px" }}>
                        <Formik
                            enableReinitialize={true}
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                resetForm
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "0px" }}>
                                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px", marginBottom: "10px" }}>
                                            <Box sx={{ flex: 3, textAlign: "center" }}>
                                                <IconButton sx={{ padding: "0px", borderRadius: "10px" }} aria-label="upload picture" component="label" variant='outlined'>
                                                    <Avatar src={file.image} sx={{ width: "100%", height: "100%", aspectRatio: 3 / 2, borderRadius: "10px" }}></Avatar>
                                                    <input hidden accept="image/*" type="file" onChange={handlePChange} />
                                                </IconButton>
                                            </Box>
                                            <Box sx={{ flex: 1, display: "flex", gap: "10px" }}>
                                                <Box sx={{ flex: 1 }}>
                                                    <InputLabel id="demo-select-Category">आकार</InputLabel>
                                                    <FormControl variant='outlined' fullWidth size='small'>
                                                        <Select
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.size}
                                                            name="size"
                                                            error={!!touched.size && !!errors.size}
                                                        >
                                                            <MenuItem value="big">Big</MenuItem>
                                                            <MenuItem value="small">Small</MenuItem>
                                                        </Select>
                                                        <FormHelperText error={!!touched.size && !!errors.size}>{touched.size && errors.size}</FormHelperText>
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{ flex: 1 }}>
                                                    <InputLabel id="demo-select-Category">समूह</InputLabel>
                                                    <FormControl variant='outlined' fullWidth size='small'>
                                                        <Select
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.cat_id}
                                                            name="cat_id"
                                                            error={!!touched.cat_id && !!errors.cat_id}
                                                        >
                                                            {category.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.id}>{item.sub_category}</MenuItem>
                                                                )
                                                            })}

                                                        </Select>
                                                        <FormHelperText error={!!touched.cat_id && !!errors.cat_id}>{touched.cat_id && errors.cat_id}</FormHelperText>
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{ flex: 1 }}>
                                                    <InputLabel id="title">रंग</InputLabel>
                                                    <FormControl variant='outlined' fullWidth size='small'>
                                                        <Select
                                                            sx={{ padding: "0px" }}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.color}
                                                            name="color"
                                                            error={!!touched.color && !!errors.color}
                                                        >
                                                            <MenuItem value="#ff0000" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#ff0000", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Red</Box></MenuItem>
                                                            <MenuItem value="#ff6600" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#ff6600", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Orange</Box></MenuItem>
                                                            <MenuItem value="#ff0088" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#ff0088", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Pink</Box></MenuItem>
                                                            <MenuItem value="#0062ff" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#0062ff", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Blue</Box></MenuItem>
                                                            <MenuItem value="#5d00ff" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#5d00ff", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Purple</Box></MenuItem>
                                                            <MenuItem value="#0f9100" sx={{ display: "flex" }}><Box sx={{ height: "100%", width: "100%", backgroundColor: "#0f9100", color: "#fff", borderRadius: "5px", textAlign: "center" }}>Green</Box></MenuItem>

                                                        </Select>
                                                        <FormHelperText error={!!touched.color && !!errors.color}>{touched.color && errors.color}</FormHelperText>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <InputLabel id="title">रंगीन शीर्षक</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Colored Title"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.color_title}
                                                name="color_title"
                                                error={!!touched.color_title && !!errors.color_title}
                                                helperText={touched.color_title && errors.color_title}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel id="title">समाचार शीर्षक</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="News Title"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.title}
                                                name="title"
                                                error={!!touched.title && !!errors.title}
                                                helperText={touched.title && errors.title}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel id="demo-select-Category">समाचार आलेख</InputLabel>
                                            <TextField
                                                fullWidth
                                                multiline
                                                minRows={4}
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize", resize: "both" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="News Article"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                                name="description"
                                                error={!!touched.description && !!errors.description}
                                                helperText={touched.description && errors.description}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", gap: "80px" }}>
                                        <Button
                                            type='submit'
                                            sx={{ margin: "20px 0px", fontSize: "15px", borderRadius: "10px" }}
                                            variant="contained"
                                        >{edit !== null ? "Update Post" : "Add Post"}</Button>
                                        {edit !== null && <Button
                                            sx={{ margin: "20px 0px", fontSize: "15px", borderRadius: "10px" }}
                                            variant="outlined"
                                            color='error'
                                            onClick={() => {
                                                setEdit(null);
                                                setFile({ file: null });
                                                setInitialValues({
                                                    cat_id: "",
                                                    color_title: "",
                                                    color: "#ff6600",
                                                    title: "",
                                                    description: "",
                                                    size: "small",
                                                })
                                            }}
                                        >Reset</Button>}
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </SwipeableEdgeDrawer>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 800,
                    minWidth: 380,
                    padding: "15px",
                    height: "80vh",
                    overflowX: "hidden"
                }}>
                    <Box sx={{ flex: 2.5 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h6" sx={{ backgroundColor: "red", color: "#ffffff", padding: "0px 4px", borderRadius: "4px", textTransform: "capitalize" }}>{onePost.eng_category}</Typography>
                            <InputLabel>{dayjs().diff(onePost.created_at, 'days') < 6 && dayjs().diff(onePost.created_at, 'days') > 0 ? dayjs().diff(onePost.created_at, 'days') + " दिन पहले" : dayjs().diff(onePost.created_at, 'hour') > 1 && dayjs().diff(onePost.created_at, 'hour') < 24 ? dayjs().diff(onePost.created_at, 'hour') + " घंटे पहले" : dayjs().diff(onePost.created_at, 'minute') < 60 ? dayjs().diff(onePost.created_at, 'minute') + " मिनट पहले" : dayjs(onePost.created_at).format('DD-MMM-YYYY')}</InputLabel>
                            <Box>
                                {onePost.status == 'pending' &&
                                    <Button size='small' variant='outlined' color='warning' onClick={(e) => { e.stopPropagation() }}>Pending</Button>
                                }
                                {onePost.status == 'approved' &&
                                    <Button size='small' variant='outlined' color='success' onClick={(e) => { e.stopPropagation() }}>Approved</Button>
                                }
                                {onePost.status == 'rejected' &&
                                    <Button size='small' variant='outlined' color='error' onClick={(e) => { e.stopPropagation() }}>Rejected</Button>
                                }
                                <IconButton color='primary' sx={{ padding: "0px 10px" }} onClick={(e) => { e.stopPropagation(); handleClose(); getOnePost(onePost.id, onePost.eng_sub_category, true) }}><Edit /></IconButton>
                                <IconButton color='error' sx={{ padding: "0px 10px" }} onClick={() => handleDel2Open()}><Delete /></IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ display: onePost.remark == null || onePost.remark == "" ? "none" : "block" }}>
                            <Typography variant='h6'>Remark: {onePost.remark}</Typography>
                        </Box>
                        <Box sx={{ margin: "10px 0px" }}>
                            <Typography variant='p' sx={{ fontSize: { xs: "18px", md: "22px" }, fontWeight: "600", textAlign: "justify", textJustify: "inter-word", color: onePost.color }}>{onePost.color_title !== null ? onePost.color_title + " : " : ""}</Typography>
                            <Typography variant='p' sx={{ fontSize: { xs: "18px", md: "22px" }, fontWeight: "600", textAlign: "justify", textJustify: "inter-word" }}>{onePost.title}</Typography>
                        </Box>
                        <Box sx={{ flex: 1, width: "100%" }}>
                            <img src={apiUrl + 'assets/post-img/' + onePost.id + '.png'} style={{ borderRadius: "10px", width: "100%", height: "auto" }} />
                        </Box>
                        <Box sx={{ paddingTop: "15px" }}>
                            {onePost.description?.split("\n").map((item, index) => {
                                return (
                                    <>
                                        <Typography key={index} component='p' sx={{ fontFamily: "'Hind', sans- serif", fontSize: { xs: "14px", md: "16px" }, fontWeight: "600", marginBottom: "20px", textAlign: "justify", textJustify: "inter-word" }}>{item}</Typography>
                                    </>
                                )
                            })}
                        </Box>
                    </Box>
                </Paper>
            </Modal>


            <Modal
                open={delOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 600,
                    minWidth: 350,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Do you want to Delete Post?
                    </Typography>
                    <Box sx={{ display: "flex", gap: '10px', marginTop: "30px" }}>
                        <Button
                            fullWidth size="large"
                            sx={{ borderRadius: "5px", margin: "0px", height: "40px" }}
                            variant="contained"
                            color="primary"
                            onClick={handleDelClose}
                        >No</Button>
                        <Button
                            fullWidth size="large"
                            sx={{ borderRadius: "5px", margin: "0px", height: "40px" }}
                            variant="contained"
                            color="error"
                            onClick={() => { deletePost(onePost.id) }}
                        >Yes</Button>
                    </Box>
                </Paper>
            </Modal>
        </>
    )
}

const checkoutSchema = yup.object().shape({
    cat_id: yup.string().required("आवश्यक"),
    color_title: yup.string().required("आवश्यक"),
    color: yup.string().required("आवश्यक"),
    title: yup.string().required("आवश्यक"),
    description: yup.string().required("आवश्यक"),
    size: yup.string().required("आवश्यक"),
})

export default NewPost