import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Box, Paper } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const drawerBleeding = 45;

const SwipeableEdgeDrawer = (props) => {
    const { window } = props;
    const toggleDrawer = (newOpen) => () => {
        props.setOpen(newOpen);
    };

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    const Puller = styled(Box)(({ theme }) => ({
        width: 30,
        height: 6,
        backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)"
    }));

    return (
        <SwipeableDrawer
            sx={{ display: { xs: "flex", md: "none" } }}
            container={container}
            anchor="bottom"
            open={props.open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <Puller />
            <Box
                sx={{
                    height: 'auto',
                    overflow: 'auto',
                }}
            >
                {props.children}
            </Box>
        </SwipeableDrawer >
    );
}

SwipeableEdgeDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
