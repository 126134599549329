import { Paper, Box, Typography, Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo1 from '../assets/icons/Newsfeed.png';
import logo2 from '../assets/icons/Strategy News.png';
import logo3 from '../assets/icons/Play Button Circled.gif';
import logo4 from '../assets/icons/Increase.png';
import { useMycontext } from './global/MyContext';

const Stats = () => {

    const { apiUrl } = useMycontext();
    const [stats, setStats] = useState();
    const [video, setVideo] = useState();

    const dailyData = () => {
        axios.get(apiUrl + 'stats/dailydata')
            .then(function (response) {
                if (response.data.status) {
                    setStats(response.data.data);
                }
                else {
                    setStats({});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const videoCount = () => {
        axios.get(apiUrl + 'stats/countvideos')
            .then(function (response) {
                if (response.data.status) {
                    setVideo(response.data.data);
                }
                else {
                    setVideo({});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        dailyData();
        videoCount();
    }, [])
    return (
        <>
            <Paper sx={{ display: "flex", justifyContent: 'space-around', flexDirection: { xs: "column", md: "row" }, borderRadius: "15px", padding: "15px", gap: "10px" }}>
                <Box sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "center" }, gap: "10px", borderRight: { xs: "none", md: "solid 1px grey" }, borderBottom: { xs: "solid 1px grey", md: "none" }, flex: 1 }}>
                    <Avatar src={logo1} sx={{ borderRadius: "0px" }} />
                    <Box sx={{ padding: "10px" }}>
                        <Typography variant='h5' color='secondary'>Your Today's Post</Typography>
                        <Typography variant='h1' sx={{ marginTop: "10px" }}>{parseInt(stats?.TotalPostToday).toLocaleString()}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "center" }, gap: "10px", borderRight: { xs: "none", md: "solid 1px grey" }, borderBottom: { xs: "solid 1px grey", md: "none" }, flex: 1 }}>
                    <Avatar src={logo2} sx={{ borderRadius: "0px" }} />
                    <Box sx={{ padding: "10px" }}>
                        <Typography variant='h5' color='secondary'>Your Total Post</Typography>
                        <Typography variant='h1' sx={{ marginTop: "10px" }}>{parseInt(stats?.TotalPostByReporter).toLocaleString()}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "center" }, gap: "10px", borderRight: { xs: "none", md: "solid 1px grey" }, borderBottom: { xs: "solid 1px grey", md: "none" }, flex: 1 }}>
                    <Avatar src={logo3} sx={{ borderRadius: "0px" }} />
                    <Box sx={{ padding: "10px" }}>
                        <Typography variant='h5' color='secondary'>Total Videos</Typography>
                        <Typography variant='h1' sx={{ marginTop: "10px" }}>{parseInt(video?.TotalVideos).toLocaleString()}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "center" }, gap: "10px", flex: 1 }}>
                    <Avatar src={logo4} sx={{ borderRadius: "0px" }} />
                    <Box sx={{ padding: "10px" }}>
                        <Typography variant='h5' color='secondary'>Total Post</Typography>
                        <Typography variant='h1' sx={{ marginTop: "10px" }}>{parseInt(stats?.TotalPost).toLocaleString()}</Typography>
                    </Box>
                </Box>

            </Paper >
        </>
    )
}

export default Stats