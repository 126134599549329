import React, { useState } from 'react'
import { Box, Paper, FormControl, OutlinedInput, InputAdornment, IconButton, Badge, Avatar, Typography } from '@mui/material';
import { Search, Notifications } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import sslogo from '../../assets/images/sslogo.png'
import { useSelector } from 'react-redux';

const Navbar = () => {

    const name = useSelector((state) => state.global.name)
    const [searchText, setSearchText] = useState("");
    const searchSuggest = (event) => {
    }

    return (
        <>
            <Paper sx={{
                borderRadius: "15px", padding: { xs: "5px", md: "5px 10px" }, display: "flex", justifyContent: "space-between", alignItems: "center", position: { xs: "fixed", md: "static" }, width: { xs: "calc(100% - 10px)", md: "100%" }, zIndex: 99
            }}>
                <Box sx={{ minWidth: "80px" }}>
                    <Box sx={{ alignItems: "center", display: { xs: "flex", md: "none" } }}>
                        <img src={sslogo} alt="Shabd Shodh" width="60px" style={{ padding: " 5px 10px" }} />
                        <Typography variant='h2'>दैनिक शब्द शोध</Typography>
                    </Box>
                </Box >
                <FormControl variant="outlined" sx={{ flex: 1, alignItems: "center", maxWidth: "40%", display: { xs: "none", md: "block" } }}>
                    <OutlinedInput
                        sx={{ height: "35px", width: "100%", borderRadius: "10px" }}
                        placeholder="Search..."
                        value={searchText}
                        onChange={searchSuggest}
                        id="outlined-adornment-search"
                        startAdornment={
                            <InputAdornment position="start">

                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                                <Search />
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            "aria-label": "weight"
                        }}
                    />
                </FormControl>
                <Box sx={{ display: "flex", alignItems: 'center', gap: "10px" }}>
                    <Typography variant='h5' sx={{ display: { xs: "none", md: "block" } }}>Hi {name.payload}</Typography>
                    <Link to="/login">
                        <IconButton sx={{ padding: "0px 10px" }}>
                            <Avatar src=""></Avatar>
                        </IconButton>
                    </Link>
                </Box>
            </Paper >
        </>
    )
}

export default Navbar