import { Paper, Box, Typography, Avatar, InputLabel, IconButton, Modal, Button, TextField, Checkbox } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import axios from 'axios';
import { useMycontext } from '../../components/global/MyContext';
import CircleLoading from '../../components/global/circleloading';
import { Facebook, Share, Twitter, WhatsApp } from '@mui/icons-material';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { useSnackbar } from 'notistack';

const Permission = () => {

    const { apiUrl } = useMycontext();
    const { enqueueSnackbar } = useSnackbar();
    const [post, setPost] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [onePost, setOnePost] = useState({});
    const [remark, setRemark] = useState("");
    const [checkbox, setCheckBox] = useState(true);
    const [dateTime, setdateTime] = useState(dayjs());
    const [open, setOpen] = useState(false);
    const handleOpen = (id, url) => { setOpen(true); getOnePost(id, url) };
    const handleClose = () => setOpen(false);

    const [editOpen, setEditOpen] = useState(false);
    const handleEditOpen = (id) => { setEditOpen(true); };
    const handleEditClose = () => { setEditOpen(false); };


    const getOnePost = (id, url) => {
        axios.get(apiUrl + 'post/getone?url=' + url + '&id=' + id)
            .then(function (response) {
                if (response.data.status) {
                    setOnePost(response.data.data);
                    setPost((prevPost) => {
                        return prevPost.map((postItem) => {
                            // Assuming there is an 'id' property to identify the object to be updated
                            if (postItem.id === response.data.data.id) {
                                // Update the specific object with the new data
                                return { ...postItem, ...response.data.data };
                            }
                            // Keep other objects unchanged
                            return postItem;
                        });
                    });
                }
                else {
                    setOnePost({});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getRecent = () => {
        setLoading(true);
        axios.get(apiUrl + 'post/getallpost?offset=' + page)
            .then(function (response) {
                if (response.data.status) {
                    setPost((prev) => [...prev, ...response.data.data]);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const callApproval = (id, url) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('remark', remark);
        formData.append('notification', checkbox);
        formData.append('datetime', dateTime);
        axios.post(apiUrl + 'post/approve', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    getOnePost(id, url);
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            })
            .finally(function () {
                handleEditClose();
            });
    }

    const callReject = (id, url) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('remark', remark);
        axios.post(apiUrl + 'post/reject', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    getOnePost(id, url);
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            })
            .finally(function () {
                handleEditClose();
            });

    }

    const handelInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 50 >= document.documentElement.scrollHeight) {
                setPage((prev) => prev + 1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handelInfiniteScroll);
        return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, []);

    useEffect(() => {
        getRecent();
    }, [page]);

    return (
        <>
            {loading && <CircleLoading />}
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", height: "calc(100vh - 80px)", overflowX: "hidden" }}>
                {post.map((item, index) => {
                    return (
                        <Paper key={index} sx={{ borderRadius: "15px", padding: "10px 10px 0px 10px", position: "relative", width: { xs: "100%", md: "calc(50% - 5px)" } }} onClick={() => { handleOpen(item.id, item.eng_sub_category) }}>
                            <Box sx={{ display: "flex", gap: "8px" }}>
                                <Box sx={{ flex: { xs: 1.6, sm: 2.5, md: 2.8 } }}>
                                    <Box sx={{ flex: 1, display: "flex", justifyContent: "space-between", gap: "10px", alignItems: "center" }}>
                                        <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="h6" sx={{ backgroundColor: item.color !== null ? item.color : "#ff6600", color: "#ffffff", padding: "0px 4px", borderRadius: "4px", textTransform: "capitalize" }}>{item.eng_category}</Typography>
                                            <Typography variant='span' sx={{ fontSize: "14px" }}>
                                                {dayjs().diff(item.created_at, 'minutes') < 60
                                                    ? dayjs().diff(item.created_at, 'minutes') + " मिनट पहले"
                                                    : dayjs().diff(item.created_at, 'hour') < 24
                                                        ? dayjs().diff(item.created_at, 'hour') + " घंटे पहले"
                                                        : dayjs().diff(item.created_at, 'day') < 6
                                                            ? dayjs().diff(item.created_at, 'day') + " दिन पहले"
                                                            : dayjs(item.created_at).format('DD-MMM-YY')}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: 'flex-end', gap: "10px", height: "25px" }}>
                                            {item.status == 'pending' &&
                                                <Button size='small' variant='outlined' color='warning' onClick={(e) => { e.stopPropagation() }}>Pending</Button>
                                            }
                                            {item.status == 'approved' &&
                                                <Button size='small' variant='outlined' color='success' onClick={(e) => { e.stopPropagation() }}>Approved</Button>
                                            }
                                            {item.status == 'rejected' &&
                                                <Button size='small' variant='outlined' color='error' onClick={(e) => { e.stopPropagation() }}>Rejected</Button>
                                            }
                                            <Button sx={{ display: { xs: "none", md: "flex" } }} size='small' variant='contained' color='success'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setOnePost({
                                                        id: item.id, eng_sub_category: item.eng_sub_category
                                                    }); handleEditOpen();
                                                }}>Change</Button>
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginTop: "10px", flex: 1 }}>
                                        <Typography variant='p' sx={{ fontSize: "18px", fontWeight: "600", textAlign: "justify", textJustify: "inter-word", color: item.color }}>{item.color_title !== null ? item.color_title + " : " : ""}</Typography>
                                        <Typography variant='p' sx={{ fontSize: "18px", fontWeight: "600", textAlign: "justify", textJustify: "inter-word" }}>{item.title}</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: "5px" }}>
                                            <Box sx={{ flex: { xs: 1.6, sm: 2.5, md: 2.8 }, display: 'flex', alignItems: "center", gap: "5px" }}>
                                                <Avatar sx={{ height: "20px", maxWidth: "20px" }}></Avatar>
                                                <Typography color='secondary' variant='h6' sx={{ marginTop: "2px" }}> {item.posted_by}</Typography>
                                            </Box>
                                            <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <IconButton sx={{ padding: "5px" }}><FacebookShareButton url="www.google.com"><Facebook /></FacebookShareButton></IconButton>
                                                <IconButton sx={{ padding: "5px" }}><TwitterShareButton url="www.google.com"><Twitter /></TwitterShareButton></IconButton>
                                                <IconButton sx={{ padding: "5px" }}><WhatsappShareButton url="www.google.com" title={post.title} separator=":: "><WhatsApp /></WhatsappShareButton></IconButton>
                                                <IconButton sx={{ padding: "5px", display: { xs: "none", sm: "block" } }} color='primary'
                                                    onClick={() => {
                                                        navigator.clipboard.writeText("TEXT_TO_BE_COPIED")
                                                            .then(() => alert("Link Copied"))
                                                    }}><Share />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ flex: 1, minHeight: "120px", paddingBottom: "10px", cursor: "pointer" }}>
                                    <Avatar src={apiUrl + "assets/post-img/" + item.id + ".png"} sx={{ borderRadius: "10px", width: "100%", height: "100%" }} />
                                </Box>
                            </Box>
                        </Paper>
                    )
                })}
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 800,
                    minWidth: 380,
                    padding: "15px",
                    height: "80vh",
                    overflowX: "hidden"
                }}>
                    <Box sx={{ flex: 2.5 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h6" sx={{ backgroundColor: "red", color: "#ffffff", padding: "0px 4px", borderRadius: "4px", textTransform: "capitalize" }}>{onePost.eng_category}</Typography>
                            <InputLabel>{dayjs().diff(onePost.created_at, 'days') < 6 && dayjs().diff(onePost.created_at, 'days') > 0 ? dayjs().diff(onePost.created_at, 'days') + " दिन पहले" : dayjs().diff(onePost.created_at, 'hour') > 1 && dayjs().diff(onePost.created_at, 'hour') < 24 ? dayjs().diff(onePost.created_at, 'hour') + " घंटे पहले" : dayjs().diff(onePost.created_at, 'minute') < 60 ? dayjs().diff(onePost.created_at, 'minute') + " मिनट पहले" : dayjs(onePost.created_at).format('DD-MMM-YYYY')}</InputLabel>
                            <Box sx={{ display: "flex", gap: "10px", height: "25px" }}>
                                {onePost.status == 'pending' &&
                                    <Button sx={{ width: { xs: "100%", md: "auto" } }} size='small' variant='outlined' color='warning' onClick={(e) => { e.stopPropagation() }}>Pending</Button>
                                }
                                {onePost.status == 'approved' &&
                                    <Button sx={{ width: { xs: "100%", md: "auto" } }} size='small' variant='outlined' color='success' onClick={(e) => { e.stopPropagation() }}>Approved</Button>
                                }
                                {onePost.status == 'rejected' &&
                                    <Button sx={{ width: { xs: "100%", md: "auto" } }} size='small' variant='outlined' color='error' onClick={(e) => { e.stopPropagation() }}>Rejected</Button>
                                }
                                <Button sx={{ width: { xs: "100%", md: "auto" } }} size='small' variant='contained' color='success' onClick={(e) => { e.stopPropagation(); handleEditOpen(); }}>Change</Button>
                            </Box>
                        </Box>
                        <Box sx={{ display: onePost.remark == null || onePost.remark == "" ? "none" : "block" }}>
                            <Typography variant='h6'>Remark: {onePost.remark}</Typography>
                        </Box>
                        <Box sx={{ margin: "10px 0px" }}>
                            <Typography variant='p' sx={{ fontSize: { xs: "18px", md: "22px" }, fontWeight: "600", textAlign: "justify", textJustify: "inter-word", color: onePost.color }}>{onePost.color_title !== null ? onePost.color_title + " : " : ""}</Typography>
                            <Typography variant='p' sx={{ fontSize: { xs: "18px", md: "22px" }, fontWeight: "600", textAlign: "justify", textJustify: "inter-word" }}>{onePost.title}</Typography>
                        </Box>
                        <Box sx={{ flex: 1, width: "100%" }}>
                            <img src={apiUrl + 'assets/post-img/' + onePost.id + '.png'} style={{ borderRadius: "10px", width: "100%", height: "auto" }} />
                        </Box>
                        <Box sx={{ paddingTop: "15px" }}>
                            {onePost.description?.split("\n").map((item, index) => {
                                return (
                                    <>
                                        <Typography key={index} component='p' sx={{ fontFamily: "'Hind', sans- serif", fontSize: { xs: "14px", md: "16px" }, fontWeight: "600", marginBottom: "20px", textAlign: "justify", textJustify: "inter-word" }}>{item}</Typography>
                                    </>
                                )
                            })}
                        </Box>
                    </Box>
                </Paper>
            </Modal>

            <Modal
                open={editOpen}
                onClose={handleEditClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 600,
                    minWidth: 350,
                    display:"flex",
                    flexDirection:"column",
                    gap:"10px",
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Please Approve Or Reject this Post?
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                        <Typography variant='h6'>Send Notification</Typography>
                        <Checkbox
                        sx={{padding:"0px"}}
                            name="checkbox"
                            checked={checkbox}
                            onChange={(e)=>setCheckBox(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                            <DateTimePicker
                                format='DD-MMM-YYYY hh:mm A'
                                label="Notificate Date Time"
                                value={dateTime}
                                onChange={(newValue) => setdateTime(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <TextField fullWidth size='small' placeholder='Remark' variant='outlined' value={remark} onChange={(e) => setRemark(e.target.value)}></TextField>
                    <Box sx={{ display: "flex", gap: '10px', marginTop: "10px" }}>
                        <Button
                            fullWidth size="large"
                            sx={{ borderRadius: "5px", margin: "0px", height: "40px" }}
                            variant="contained"
                            color="error"
                            onClick={() => {
                                callReject(onePost.id, onePost.eng_sub_category)
                            }}
                        >Reject</Button>
                        <Button
                            fullWidth size="large"
                            sx={{ borderRadius: "5px", margin: "0px", height: "40px" }}
                            variant="contained"
                            color="success"
                            onClick={() => {
                                callApproval(onePost.id, onePost.eng_sub_category)
                            }}
                        >Approved</Button>
                    </Box>
                </Paper>
            </Modal>
        </>
    )
}

export default Permission