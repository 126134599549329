import { useEffect, useMemo, useState, lazy, Suspense } from "react";
import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { Close } from "@mui/icons-material";
import axios from "axios";
import { themeSettings } from "./theme";
import CircleLoading from "./components/global/circleloading";
import { MyContextProvider } from "./components/global/MyContext";

import ALogin from './view/admin/login/login';
import ALoginotp from './view/admin/login/loginotp';
import APassVerify from './view/admin/login/passverify';
import AForgetotp from './view/admin/login/forgetotp';
import AForgetVerify from './view/admin/login/forgetverify';
import AReset from './view/admin/login/reset';

import Main from './view/main';
import Home from './view/home';
import Permission from './view/admin/permission';
import NewPost from "./view/newpost";
import Video from "./view/video";
import Category from "./view/category";
import Advertisement from "./view/admin/advertisement";
import Epaper from "./view/epaper";

function App() {

  const [user, setUser] = useState({});
  const [role, setRole] = useState("reporter");
  const theme = useMemo(() => createTheme(themeSettings()));
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = useSelector((state) => state.global.userId);

  const auth = () => {
    axios.post(apiUrl + 'auth/auth')
      .then(function (response) {
        if (response.data.status) {
          setRole(response.data.data.role);
        }
        else {
          setRole(null);
        }
      })
      .catch(function (error) {
        setRole(null);
      });
  }

  useEffect(() => {
    auth();
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          style={{ marginTop: "50px", fontSize: { xs: "12px", md: "14px" } }}
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          preventDuplicate={true}
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)}>
              <Close fontSize="small" sx={{ color: "#fff" }} />
            </IconButton>
          )}
        >
          <MyContextProvider value={{ apiUrl, user, setUser }}>

            <BrowserRouter>
              <Routes>
                {userId.payload == null && <Route path="/*" element={<Navigate to="/login" replace />} />}
                <Route exact path="/login" element={<Suspense fallback={<CircleLoading />}><ALogin setRole={setRole} /></Suspense>} />
                <Route exact path="/login-otp" element={<Suspense fallback={<CircleLoading />}><ALoginotp /></Suspense>} />
                <Route exact path="/pass-verify" element={<Suspense fallback={<CircleLoading />}><APassVerify setRole={setRole} /></Suspense>} />
                <Route exact path="/forget" element={<Suspense fallback={<CircleLoading />}><AForgetotp /></Suspense>} />
                <Route exact path="/forget-verify" element={<Suspense fallback={<CircleLoading />}><AForgetVerify /></Suspense>} />
                <Route exact path="/reset" element={<Suspense fallback={<CircleLoading />}><AReset setRole={setRole} /></Suspense>} />
                {userId.payload !== null &&
                  <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><Main setRole={setRole} role={role} /></Suspense>} >
                    <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><Home /></Suspense>} />
                    <Route exact path="/new-post" element={<Suspense fallback={<CircleLoading />}><NewPost /></Suspense>} />
                    <Route exact path="/video" element={<Suspense fallback={<CircleLoading />}><Video /></Suspense>} />
                    <Route exact path="/upload-epaper" element={<Suspense fallback={<CircleLoading />}><Epaper /></Suspense>} />
                    <Route exact path="/category" element={<Suspense fallback={<CircleLoading />}><Category /></Suspense>} />
                    <Route exact path="/advertisement" element={<Suspense fallback={<CircleLoading />}><Advertisement /></Suspense>} />
                    {role == 'admin' &&
                      <Route exact path="/permission" element={<Suspense fallback={<CircleLoading />}><Permission /></Suspense>} />
                    }
                    <Route exact path="*" element={<h1>404</h1>} />
                  </Route>
                }
              </Routes>
            </BrowserRouter>
          </MyContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
