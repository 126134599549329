import React, { useState, useEffect } from 'react'
import { Button, Avatar, Box, Paper, Typography, FormControl, Switch, InputAdornment, Select, MenuItem, FormHelperText, InputLabel, TextField, IconButton } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DataGrid } from '@mui/x-data-grid';
import { Formik } from "formik";
import { ArrowDownward, ArrowUpward, Event } from '@mui/icons-material';
import Compressor from 'compressorjs';
import * as yup from "yup";
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import clsx from "clsx";
import { Delete, Edit, Search } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useNavigate } from 'react-router-dom';
import { setHiddenColumn } from '../store/globalSlice';
import { themeSettings } from '../theme';
import { useTheme } from '@emotion/react';
import { useMycontext } from '../components/global/MyContext';
import SwipeableEdgeDrawer from '../components/bottomdrawer';



const Category = () => {

    const dispatch = useDispatch();
    const { apiUrl } = useMycontext();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const hiddenColumn = useSelector((state) => state.global.hiddenColumn);
    const [file, setFile] = useState({});
    const [dopen, setDOpen] = useState(false);
    const handleDOpen = () => setDOpen(true);
    const handledClose = () => setDOpen(false);

    const handleImgChange = (event) => {
        setFile({ file: event.target.files[0], image: URL.createObjectURL(event.target.files[0]) });
    }

    const handleFormSubmit = (values, onSubmitProps) => {
        const formData = new FormData();
        formData.append('category', values.category);
        formData.append('engcategory', values.eng_category);
        formData.append('subcategory', values.subcategory);
        formData.append('engsubcategory', values.eng_sub_category);
        formData.append('visiblity', values.visiblity);
        formData.append('status', values.status);
        if (file.file !== null) {
            formData.append('photo', file.file, file.file.name);
        }
        else {
            formData.append('photo', "");
        }
        setLoading(true);
        axios.post(apiUrl + 'category/add', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    setFile({ file: null });
                    onSubmitProps.resetForm();
                    setLoading(false);
                    getCategory();
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const getCategory = () => {
        axios.get(apiUrl + 'category/getall')
            .then(function (response) {
                if (response.data.status) {
                    setRows(response.data.data);
                }
                else {
                    setRows([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getCategory();
    }, []);

    const columns = [
        {
            field: 's_no',
            headerName: 'S.No',
            type: "number",
            filterable: false,
            width: 60,
        },
        {
            field: 'icon',
            headerName: 'Icon',
            width: 70,
            renderCell: (params) => {
                return (
                    <Avatar src={apiUrl + 'assets/cat-img/' + params.id + '.png'}></Avatar>
                );
            },
        },
        {
            field: 'category',
            headerName: 'Category',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'eng_category',
            headerName: 'English Category',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'sub_category',
            headerName: 'Sub-Category',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'eng_sub_category',
            headerName: 'Eng Sub-Category',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'visiblity',
            headerName: 'Visiblity',
            width: 130,
            disableClickEventBubbling: true,

            renderCell: (params) => {
                const onClick = (e) => {
                    const currentRow = params.row;
                    axios.get(apiUrl + 'category/visiblity?id=' + currentRow.id)
                        .then(function (response) {
                            if (response.data.status) {
                                const updatedRows = rows.map(row => {
                                    if (row.id === currentRow.id) {
                                        return { ...row, visiblity: currentRow.visiblity == '1' ? '0' : '1' };
                                    }
                                    return row; // Otherwise, return the original row
                                });
                                setRows(updatedRows);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
                return (
                    <Switch
                        checked={params.row.visiblity == '1' ? true : false}
                        onChange={onClick}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            renderCell: (params) => {
                const onClick = () => {
                    const currentRow = params.row;
                    axios.get(apiUrl + 'category/status?id=' + currentRow.id)
                        .then(function (response) {
                            if (response.data.status) {
                                const updatedRows = rows.map(row => {
                                    if (row.id === currentRow.id) {
                                        return { ...row, status: currentRow.status == '1' ? '0' : '1' };
                                    }
                                    return row; // Otherwise, return the original row
                                });
                                setRows(updatedRows);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
                return (
                    <Switch
                        checked={params.row.status == '1' ? true : false}
                        onChange={onClick}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                );
            },
        },
        {
            field: 'upward',
            headerName: 'UP',
            width: 60,
            renderCell: (params) => {
                const onClick = (e) => {
                    alert('up');
                };
                return (
                    <IconButton onClick={onClick} sx={{ border: "2px solid " + theme.palette.primary.main, padding: "5px" }}><ArrowUpward color="primary" /></IconButton>
                );
            },
        },
        {
            field: 'downward',
            headerName: 'Down',
            width: 60,
            renderCell: (params) => {
                const onClick = (e) => {
                    alert('down');
                };
                return (
                    <IconButton onClick={onClick} sx={{ border: "2px solid " + theme.palette.primary.main, padding: "5px" }}><ArrowDownward color="primary" /></IconButton>
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 160,
            valueGetter: (params) =>
                `${dayjs(params.row.created_at).format('DD-MMM-YYYY')}`,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 130,
            renderCell: (params) => {
                const onClick = (event) => {
                    event.stopPropagation();
                    setLoading(true);
                    axios.delete(apiUrl + 'category/delete?id=' + params.row.id)
                        .then(function (response) {
                            if (response.data.status) {
                                enqueueSnackbar(response.data.message, { variant: "success" });
                                getCategory();
                            }
                            else {
                                enqueueSnackbar(response.data.message, { variant: "error" });
                            }
                            setLoading(false);
                        })
                        .catch(function (error) {
                            console.log(error);
                            setLoading(false);
                        });
                };
                return (
                    <>
                        <IconButton onClick={onClick}><Delete color='error' /></IconButton>
                    </>

                );
            },
        },
    ];

    return (
        <>
            <Box>
                <Paper sx={{ borderRadius: "15px", padding: { xs: "5px", md: "10px 5px 0px 10px" }, flex: 1, display: { xs: "block", md: "none" } }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                        <Typography variant='h3' sx={{ padding: "0px 5px" }}>समूह विवरण</Typography>
                        <Button
                            onClick={handleDOpen}
                            type='submit'
                            sx={{ fontSize: "15px", borderRadius: "10px" }}
                            variant="contained"
                        >Add Category</Button>
                    </Box>
                </Paper>
                <Paper sx={{ padding: "10px", display: { xs: "none", md: "block" } }}>
                    <Formik
                        enableReinitialize={true}
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography variant='h3' sx={{ padding: "0px 5px" }}>समूह विवरण</Typography>
                                    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px" }}>
                                        <IconButton sx={{ padding: "0px" }} aria-label="upload picture" component="label" variant='outlined'>
                                            <Avatar src={file.image} sx={{ width: { xs: "100px", md: "75px" }, height: { xs: "100px", md: "75px" } }}></Avatar>
                                            <input hidden accept="image/*" type="file" onChange={handleImgChange} />
                                        </IconButton>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="category">वर्ग</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.category}
                                                name="category"
                                                error={!!touched.category && !!errors.category}
                                                helperText={touched.category && errors.category}
                                            />
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="category">Eng Category</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                variant="outlined"
                                                type="text"
                                                placeholder="English Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.eng_category}
                                                name="eng_category"
                                                error={!!touched.eng_category && !!errors.eng_category}
                                                helperText={touched.eng_category && errors.eng_category}
                                            />
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="sub-category">उपवर्ग</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Sub Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.subcategory}
                                                name="subcategory"
                                                error={!!touched.subcategory && !!errors.subcategory}
                                                helperText={touched.subcategory && errors.subcategory}
                                            />
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="sub-category">Eng Sub Category</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "lowercase" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Eng Sub Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.eng_sub_category}
                                                name="eng_sub_category"
                                                error={!!touched.eng_sub_category && !!errors.eng_sub_category}
                                                helperText={touched.eng_sub_category && errors.eng_sub_category}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '10px', flex: 1 }}>
                                            <Box sx={{ flex: 1 }}>
                                                <InputLabel id="demo-select-Category">दृश्यता</InputLabel>
                                                <FormControl variant='outlined' fullWidth size='small'>
                                                    <Select
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.visiblity}
                                                        name="visiblity"
                                                        error={!!touched.visiblity && !!errors.visiblity}
                                                    >
                                                        <MenuItem value="1">Show</MenuItem>
                                                        <MenuItem value="0">Hidden</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={!!touched.visiblity && !!errors.visiblity}>{touched.visiblity && errors.visiblity}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <InputLabel id="demo-select-Category">स्थिति</InputLabel>
                                                <FormControl variant='outlined' fullWidth size='small'>
                                                    <Select
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.status}
                                                        name="status"
                                                        error={!!touched.status && !!errors.status}
                                                    >
                                                        <MenuItem value="1">Show</MenuItem>
                                                        <MenuItem value="0">Hidden</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={!!touched.status && !!errors.status}>{touched.status && errors.status}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        <Button
                                            type='submit'
                                            sx={{ margin: "20px 0px", fontSize: "15px", borderRadius: "10px" }}
                                            variant="contained"
                                        >Add Category</Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Paper>
                <Paper sx={{ maxWidth: { xs: "calc(100vw - 15px)", md: "calc(100vw - 230px)" }, height: "calc(100vh - 238px)", marginTop: "10px", borderRadius: "15px" }}>
                    <DataGrid
                        sx={{ borderRadius: "15px" }}
                        loading={loading}
                        rows={rows}
                        columns={columns}
                        onPaginationModelChange={(params) => setPage(params.page)}
                        hideFooterSelectedRowCount={true}
                        pageSizeOptions={[100]}
                        initialState={{
                            columns: {
                                columnVisibilityModel: hiddenColumn.payload
                            },
                        }}
                        //onRowClick={(e) => getTeamDetails(e.row.associate_id)}
                        onColumnVisibilityModelChange={(params) => { dispatch(setHiddenColumn(params)) }}
                        rowCount={rowCount}
                        hideFooter
                        paginationMode="server"
                    />
                </Paper>
            </Box>

            <SwipeableEdgeDrawer open={dopen} setOpen={setDOpen}>
                <Box sx={{ padding: "10px" }}>


                    <Formik
                        enableReinitialize={true}
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography variant='h3' sx={{ padding: "0px 5px" }}>समूह विवरण</Typography>
                                    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px" }}>
                                        <IconButton sx={{ padding: "0px" }} aria-label="upload picture" component="label" variant='outlined'>
                                            <Avatar src={file.image} sx={{ width: { xs: "100px", md: "75px" }, height: { xs: "100px", md: "75px" } }}></Avatar>
                                            <input hidden accept="image/*" type="file" onChange={handleImgChange} />
                                        </IconButton>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="category">वर्ग</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.category}
                                                name="category"
                                                error={!!touched.category && !!errors.category}
                                                helperText={touched.category && errors.category}
                                            />
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="category">Eng Category</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                variant="outlined"
                                                type="text"
                                                placeholder="English Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.eng_category}
                                                name="eng_category"
                                                error={!!touched.eng_category && !!errors.eng_category}
                                                helperText={touched.eng_category && errors.eng_category}
                                            />
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="sub-category">उपवर्ग</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "capitalize" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Sub Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.subcategory}
                                                name="subcategory"
                                                error={!!touched.subcategory && !!errors.subcategory}
                                                helperText={touched.subcategory && errors.subcategory}
                                            />
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <InputLabel id="sub-category">Eng Sub Category</InputLabel>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                inputProps={{ style: { textTransform: "lowercase" } }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Eng Sub Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.eng_sub_category}
                                                name="eng_sub_category"
                                                error={!!touched.eng_sub_category && !!errors.eng_sub_category}
                                                helperText={touched.eng_sub_category && errors.eng_sub_category}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '10px', flex: 1 }}>
                                            <Box sx={{ flex: 1 }}>
                                                <InputLabel id="demo-select-Category">दृश्यता</InputLabel>
                                                <FormControl variant='outlined' fullWidth size='small'>
                                                    <Select
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.visiblity}
                                                        name="visiblity"
                                                        error={!!touched.visiblity && !!errors.visiblity}
                                                    >
                                                        <MenuItem value="1">Show</MenuItem>
                                                        <MenuItem value="0">Hidden</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={!!touched.visiblity && !!errors.visiblity}>{touched.visiblity && errors.visiblity}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <InputLabel id="demo-select-Category">स्थिति</InputLabel>
                                                <FormControl variant='outlined' fullWidth size='small'>
                                                    <Select
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.status}
                                                        name="status"
                                                        error={!!touched.status && !!errors.status}
                                                    >
                                                        <MenuItem value="1">Show</MenuItem>
                                                        <MenuItem value="0">Hidden</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={!!touched.status && !!errors.status}>{touched.status && errors.status}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", gap: "80px" }}>
                                            <Button
                                                type='submit'
                                                sx={{ margin: "20px 0px", fontSize: "15px", borderRadius: "10px" }}
                                                variant="contained"
                                            >Add Category</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </SwipeableEdgeDrawer>
        </>
    )
}

const initialValues = {
    category: "",
    eng_category: "",
    subcategory: "",
    eng_sub_category: "",
    visiblity: 0,
    status: 1,
}

const checkoutSchema = yup.object().shape({
    category: yup.string().required("आवश्यक"),
    eng_category: yup.string().required("आवश्यक"),
    subcategory: yup.string().required("आवश्यक"),
    eng_sub_category: yup.string().required("आवश्यक"),
    visiblity: yup.string().required("आवश्यक"),
    status: yup.string().required("आवश्यक"),
})

export default Category