import React from 'react'
import { Paper, Box, Typography, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { HomeSharp, AddCard, Category, Newspaper, Flaky } from '@mui/icons-material';

const Navdown = () => {
    return (
        <>
            <Box sx={{ position: "fixed", zIndex: 99, bottom: "5px", width: "calc(100% - 10px)", display: { xs: "block", md: "none" } }}>
                <Paper sx={{ display: "flex", justifyContent: "space-around", borderRadius: "15px" }}>
                    <NavLink to={"/"} >
                        {({ isActive }) => (
                            <Button color={isActive ? "primary" : "secondary"}
                                fullWidth
                                sx={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px",
                                    border: "none",
                                    padding: "0px"
                                }}
                            >
                                <HomeSharp fontSize={isActive ? "large" : "medium"} />
                                <Typography variant='p' sx={{ fontSize: "15px" }}>होम</Typography>
                            </Button>
                        )}
                    </NavLink>

                    <NavLink to={"/permission"} >
                        {({ isActive }) => (
                            <Button color={isActive ? "primary" : "secondary"}
                                fullWidth
                                sx={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px",
                                    border: "none",
                                    padding: "0px"
                                }}
                            >
                                <Flaky fontSize={isActive ? "large" : "medium"} />
                                <Typography variant='p' sx={{ fontSize: "15px" }}>अनुमति</Typography>
                            </Button>
                        )}
                    </NavLink>

                    <NavLink to={"/new-post"} >
                        {({ isActive }) => (
                            <Button color={isActive ? "primary" : "secondary"}
                                fullWidth
                                sx={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px",
                                    border: "none",
                                    padding: "0px"
                                }}
                            >
                                <AddCard fontSize={isActive ? "large" : "medium"} />
                                <Typography variant='p' sx={{ fontSize: "15px" }}>नई पोस्ट</Typography>
                            </Button>
                        )}
                    </NavLink>

                    <NavLink to={"/upload-epaper"} >
                        {({ isActive }) => (
                            <Button color={isActive ? "primary" : "secondary"}
                                fullWidth
                                sx={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px",
                                    border: "none",
                                    padding: "0px"
                                }}
                            >
                                <Newspaper fontSize={isActive ? "large" : "medium"} />
                                <Typography variant='p' sx={{ fontSize: "15px" }}>ई-पेपर</Typography>
                            </Button>
                        )}
                    </NavLink>

                    <NavLink to={"/category"} >
                        {({ isActive }) => (
                            <Button color={isActive ? "primary" : "secondary"}
                                fullWidth
                                sx={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px",
                                    border: "none",
                                    padding: "0px"
                                }}
                            >
                                <Category fontSize={isActive ? "large" : "medium"} />
                                <Typography variant='p' sx={{ fontSize: "15px" }}>समूह</Typography>
                            </Button>
                        )}
                    </NavLink>
                </Paper>
            </Box>
        </>
    )
}

export default Navdown