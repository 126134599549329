import { Button, Paper, Typography, Box, TextField, FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText, useTheme } from '@mui/material'
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import loginVector from '../../../assets/images/login.png';
import sslogo from '../../../assets/images/sslogo.png';
import { setName, setUserId } from '../../../store/globalSlice';
import { useMycontext } from '../../../components/global/MyContext';

const Login = (props) => {

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { apiUrl } = useMycontext();
    const theme = useTheme();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleFormSubmit = (values) => {
        if (values.mobile === '' || values.password === '') {
            setError(true);
            setErrorMsg("Please Fill all details.");
        }
        else {
            callLogin(values);
        }
    }

    const callLogin = (values) => {
        const formData = new FormData();
        formData.append('mobile_no', values.mobile);
        formData.append('password', values.password);
        axios.post(apiUrl + 'admin/register/login', formData)
            .then(function (response) {
                if (response.data.status) {
                    dispatch(setUserId(response.data.data.id));
                    dispatch(setName(response.data.data.name));
                    props.setRole(response.data.data.role);
                    navigate('/');
                }
                else {
                    setError(true);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    return (
        <>
            <Box sx={{
                height: "100vh",
                backgroundImage: "url(/image/background.webp)",
                backgroundPosition: "center",
                backgroundSize: "100% auto"
            }}>
                <Paper sx={{
                    display: "flex",
                    width: "90%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "20px",
                }}>
                    <Box sx={{
                        backgroundColor: "#ddffff",
                        flex: 2,
                        textAlign: "center",
                        display: { xs: "none", md: "flex" },
                        justifyContent: "center",
                        alignItems: "center",
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        height: { xs: "auto", md: "calc(100vh - 100px)" }
                    }}>
                        <Box>
                            <img src={loginVector} alt="login-img" width="100%" />
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: { xs: "auto", md: "calc(100vh - 100px)" }
                        }}>
                            <Box sx={{
                                width: "100%",
                                padding: "70px",
                            }}>
                                <Box sx={{ textAlign: "center" }}>
                                    <img src={sslogo} alt="shabd shodh" height="200px" />
                                </Box>
                                <Box sx={{ textAlign: "center", margin: "10px" }}>
                                    <Typography variant='h1'>स्वागतं रिपोर्टर</Typography>
                                </Box>
                                <Formik
                                    onSubmit={handleFormSubmit}
                                    initialValues={initialValues}
                                    validationSchema={checkoutSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type="number"
                                                    label="Mobile"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.mobile}
                                                    name="mobile"
                                                    error={!!touched.mobile && !!errors.mobile}
                                                    helperText={touched.mobile && errors.mobile}
                                                />
                                                <FormControl variant="standard" fullWidth>
                                                    <InputLabel>Password</InputLabel>
                                                    <Input
                                                        type={showPassword ? 'text' : 'password'}
                                                        label="Password"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.password}
                                                        name="password"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        error={!!touched.password && !!errors.password}
                                                    />
                                                    <FormHelperText error={!!touched.password && !!errors.password}>{touched.password && errors.password}</FormHelperText>
                                                </FormControl>
                                                <Box sx={{ textAlign: "right" }}><Link to='/forget'><Typography color={theme.palette.primary.main} variant='h6'>Forget Password?</Typography></Link></Box>
                                                <Button
                                                    fullWidth
                                                    sx={{ borderRadius: "40px", marginTop: "20px" }}
                                                    variant="contained"
                                                    type='submit'
                                                >Login</Button>
                                                <Link to='/login-otp'><Button
                                                    fullWidth
                                                    sx={{ borderRadius: "40px" }}
                                                    variant="outlined"
                                                >Login with OTP</Button></Link>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </>


    )
}

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const passExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

const checkoutSchema = yup.object().shape({
    mobile: yup.string().min(10).max(10).matches(phoneRegExp, "Phone number is not valid.").required("Required"),
    password: yup.string().matches(passExp, "Password must contain minimum eight characters, at least one letter and one number.").required("Required"),
});

const initialValues = {
    mobile: "",
    password: "",
};

export default Login