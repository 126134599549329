import React, { useState, useRef, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

//PDFjs worker from an external cdn

const Pdf = ({ url, ratio, page = 1, setTotalPage = () => { } }) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setTotalPage(numPages);
    }

    const useContainerDimensions = myRef => {
        const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

        useEffect(() => {
            const getDimensions = () => ({
                width: myRef.current.offsetWidth,
                height: myRef.current.offsetHeight
            })

            const handleResize = () => {
                setDimensions(getDimensions())
            }

            if (myRef.current) {
                setDimensions(getDimensions())
            }

            window.addEventListener("resize", handleResize)

            return () => {
                window.removeEventListener("resize", handleResize)
            }
        }, [myRef])

        return dimensions;
    };

    const componentRef = useRef();
    const { width, height } = useContainerDimensions(componentRef)

    return (
        <>
            <Box ref={componentRef} sx={{ width: "100%" }}>
                <div className="doc-pdf">
                    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page
                            scale={1}
                            devicePixelRatio={ratio}
                            pageNumber={page}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            width={width}
                            height={height}
                        />
                    </Document>
                </div>
            </Box>
        </>
    )
}

export default Pdf