import { Button, Paper, Typography, Box, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useSnackbar } from 'notistack';
import loginVector from '../../../assets/images/login.png';
import sslogo from '../../../assets/images/sslogo.png';
import { useMycontext } from '../../../components/global/MyContext';

const PassVerify = (props) => {

    const [error, setError] = useState(true);
    const [errorMsg, setErrorMsg] = useState('OTP Send.');
    const [otp, setOtp] = useState('');

    const navigate = useNavigate();
    const { user, setUser } = useMycontext();

    useEffect(() => {
        let timer;

        if (error) {
            timer = setTimeout(() => {
                setError(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [error]);

    const handleChange = (newValue) => {
        setOtp(newValue)
        if (newValue / 1000 > 1) {
            callVerify(newValue);
        }
    }
    const callVerify = (newValue) => {
        setUser({ "mobile": user.mobile, "otp": newValue });
        navigate('/reset');
    }

    return (
        <>
            <Box sx={{
                height: "100vh",
                backgroundImage: "url(/image/background.webp)",
                backgroundPosition: "center",
                backgroundSize: "100% auto"
            }}>
                <Paper sx={{
                    display: "flex",
                    width: "90%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "20px"
                }}>
                    <Box sx={{
                        backgroundColor: "#ddffff",
                        flex: 2,
                        textAlign: "center",
                        display: { xs: "none", md: "flex" },
                        justifyContent: "center",
                        alignItems: "center",
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        height: { xs: "auto", md: "calc(100vh - 100px)" }
                    }}>
                        <Box>
                            <img src={loginVector} alt="login-img" width="100%" />
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: { xs: "auto", md: "calc(100vh - 100px)" }
                        }}>
                            <Box sx={{
                                maxWidth: "400px",
                                padding: "50px",
                                textAlign: "center"
                            }}>
                                <Box sx={{ textAlign: "center" }}>
                                    <img src={sslogo} alt="" height="200px" />
                                </Box>
                                <Box sx={{ textAlign: "center", margin: "10px" }}>
                                    <Typography variant='h1'>स्वागतं रिपोर्टर</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
                                    <MuiOtpInput length={4} value={otp} TextFieldsProps={{ type: "number" }} onChange={handleChange} sx={{ gap: 1 }} />
                                    <Button
                                        fullWidth
                                        sx={{ borderRadius: "40px", marginTop: "20px" }}
                                        variant="contained"
                                        onClick={() => { callVerify(otp); }}
                                    >Verify</Button>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Paper >
            </Box >
        </>


    )
}

export default PassVerify