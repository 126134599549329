import { Paper, Box, Typography, Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react';
import Stats from '../components/stats';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { useMycontext } from '../components/global/MyContext';
import axios from 'axios';
import dayjs from 'dayjs';

const chartSetting = {
    height: 500,
};

const Home = () => {

    const valueFormatter = (value) => parseInt(value);

    const { apiUrl } = useMycontext();
    const [report, setReport] = useState([{ posted_by: "", PostCount: 0 }]);
    const [label, setLabel] = useState([]);
    const [allReport, setAllReport] = useState([]);

    const ReporterReport = () => {
        axios.get(apiUrl + 'stats/reporterreport')
            .then(function (response) {
                if (response.data.status) {
                    setReport(response.data.data.map(entry => {
                        return { ...entry, PostCount: parseInt(entry.PostCount) };
                    }));
                }
                else {
                    setReport([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const AllReporterReport = () => {
        axios.get(apiUrl + 'stats/allreporterreport')
            .then(function (response) {
                if (response.data.status) {
                    setLabel(getXLabels(response.data.data).map(date => dayjs(date).format('DD-MMM')));
                    setAllReport(generateReporterData(response.data.data, getXLabels(response.data.data)));
                }
                else {
                    setAllReport([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function getXLabels(data) {
        const xLabelsSet = new Set();
        data.forEach(entry => {
            xLabelsSet.add(entry.PostDate);
        });
        return Array.from(xLabelsSet);
    }

    function generateReporterData(data, xLabels) {
        const reporterDataMap = new Map();

        data.forEach(entry => {
            const { reporter_id, PostDate, TotalPost, posted_by } = entry;

            if (!reporterDataMap.has(reporter_id)) {
                reporterDataMap.set(reporter_id, { reporter_id, label: posted_by, data: new Array(xLabels.length).fill(0) });
            }

            const reporterData = reporterDataMap.get(reporter_id);
            const index = xLabels.indexOf(PostDate);

            if (index !== -1) {
                reporterData.data[index] = parseInt(TotalPost, 10) || 0;
            }
        });

        return Array.from(reporterDataMap.values());
    }


    useEffect(() => {
        ReporterReport();
        AllReporterReport();
    }, []);

    return (
        <>
            <Stats />
            <Box sx={{ display: { xs: "block", md: "flex" } }}>
                <BarChart
                    dataset={report}
                    yAxis={[{ scaleType: 'band', dataKey: 'posted_by' }]}
                    series={[{ dataKey: 'PostCount', label: 'Daily Reporter Data', valueFormatter }]}
                    layout="horizontal"
                    {...chartSetting}
                />
                <LineChart
                    series={allReport}
                    xAxis={[{ scaleType: 'point', data: label }]}
                    {...chartSetting}
                />
            </Box>
        </>
    )
}

export default Home