import React from 'react'
import { Box, Paper, Typography, Button } from '@mui/material';
import sslogo from '../../assets/images/sslogo.png';
import { AdUnits, AddCard, Category, Flaky, HomeSharp, Logout, Newspaper, VideoSettings } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setName, setUserId } from '../../store/globalSlice';
import { useMycontext } from './MyContext';

const Sidebar = ({ role, setRole }) => {

    const { apiUrl } = useMycontext();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        axios.post(apiUrl + 'auth/logout')
            .then(function (response) {
                if (response.data.status) {
                    setRole(response.data.data.role);
                    setRole(null);
                    dispatch(setName(null));
                    dispatch(setUserId(null));
                    navigate('/login');
                }
                else {
                    setRole(null);
                    dispatch(setName(null));
                    dispatch(setUserId(null));
                }
            })
            .catch(function (error) {
                setRole(null);
                dispatch(setName(null));
                dispatch(setUserId(null));
            });
    }
    return (
        <>
            <Box sx={{ padding: "10px 0px 10px 10px", height: "100vh", display: { xs: "none", md: "block" } }}>
                <Paper sx={{ width: "200px", display: "flex", justifyContent: "space-between", flexDirection: "column", gap: "15px", borderRadius: "15px", height: "100%", padding: "15px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                        <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', padding: "10px 0px" }}>
                            <img src={sslogo} alt="Shabd Shodh" width="90px" />
                            <Typography variant='h2'>दैनिक शब्द शोध</Typography>
                        </Box>

                        <NavLink to={"/"}>
                            {({ isActive }) => (
                                <Button fullWidth size='' variant={isActive ? 'contained' : 'outlined'} color={isActive ? "primary" : "secondary"} startIcon={<HomeSharp sx={{ marginLeft: "20px" }} />} sx={{ borderRadius: "10px", justifyContent: "flex-start", gap: "10px" }}>होम</Button>
                            )}
                        </NavLink>

                        {role == 'admin' && <NavLink to={"/permission"}>
                            {({ isActive }) => (
                                <Button fullWidth size='' variant={isActive ? 'contained' : 'outlined'} color={isActive ? "primary" : "secondary"} startIcon={<Flaky sx={{ marginLeft: "20px" }} />} sx={{ borderRadius: "10px", justifyContent: "flex-start", gap: "10px" }}>अनुमति</Button>
                            )}
                        </NavLink>}

                        <NavLink to={"/new-post"}>
                            {({ isActive }) => (
                                <Button fullWidth size='' variant={isActive ? 'contained' : 'outlined'} color={isActive ? "primary" : "secondary"} startIcon={<AddCard sx={{ marginLeft: "20px" }} />} sx={{ borderRadius: "10px", justifyContent: "flex-start", gap: "10px" }}>नई पोस्ट</Button>
                            )}
                        </NavLink>

                        <NavLink to={"/video"}>
                            {({ isActive }) => (
                                <Button fullWidth size='' variant={isActive ? 'contained' : 'outlined'} color={isActive ? "primary" : "secondary"} startIcon={<VideoSettings sx={{ marginLeft: "20px" }} />} sx={{ borderRadius: "10px", justifyContent: "flex-start", gap: "10px" }}>वीडियो</Button>
                            )}
                        </NavLink>

                        <NavLink to={"/upload-epaper"}>
                            {({ isActive }) => (
                                <Button fullWidth size='' variant={isActive ? 'contained' : 'outlined'} color={isActive ? "primary" : "secondary"} startIcon={<Newspaper sx={{ marginLeft: "20px" }} />} sx={{ borderRadius: "10px", justifyContent: "flex-start", gap: "10px" }}>ई-पेपर</Button>
                            )}
                        </NavLink>

                        <NavLink to={"/category"}>
                            {({ isActive }) => (
                                <Button fullWidth size='' variant={isActive ? 'contained' : 'outlined'} color={isActive ? "primary" : "secondary"} startIcon={<Category sx={{ marginLeft: "20px" }} />} sx={{ borderRadius: "10px", justifyContent: "flex-start", gap: "10px" }}>समूह</Button>
                            )}
                        </NavLink>

                        <NavLink to={"/advertisement"}>
                            {({ isActive }) => (
                                <Button fullWidth size='' variant={isActive ? 'contained' : 'outlined'} color={isActive ? "primary" : "secondary"} startIcon={<AdUnits sx={{ marginLeft: "20px" }} />} sx={{ borderRadius: "10px", justifyContent: "flex-start", gap: "10px" }}>विज्ञापन</Button>
                            )}
                        </NavLink>

                    </Box>
                    <Button variant='outlined' startIcon={<Logout />} sx={{ borderRadius: "10px" }} onClick={() => { handleLogout() }}>लॉग आउट</Button>
                </Paper>
            </Box>
        </>
    )
}

export default Sidebar